<template>
  <div>
    <!-- <this-header /> -->
    <div class="anan-set-nav-conttainer mt-150">
      <router-link :to="{name : 'new-pages'}">
        <b-button
          class="btn btn-gradient-primary mb-1 mr-50 mr-50 d-md-none"
        >
          <i class="fas fa-arrow-left mr-25" /> {{ $t('key-126') }}
        </b-button>
      </router-link>
      <div class="row">
        <div class="col-lg-2 col-md-2 col-sm-12">
          <side-bar class="d-none d-md-block" />
        </div>

        <div class="col-lg-10 col-md-10 col-sm-12">
          <div class="anan-set-nav-conttainer">
            <b-overlay
              :show="show"
              variant="transparent"
              opacity="0.99"
              blur="5px"
              rounded="sm"
            >
              <template #overlay>
                <div class="text-center">
                  <p id="cancel-label">
                    กรุณารอสักครู่...
                  </p>
                </div>
              </template>

              <b-card>
                <div class="d-flex justify-content-between">
                  <div class="d-flex">
                    <img
                      src="@/assets/images/anan-img/icon/wallet.png"
                      alt=""
                      height="70"
                      class="mr-2"
                    >

                    <div>
                      <h5 class="font-weight-bolder">
                        {{ $t('availableBalance') }}
                      </h5>
                      <h2 class="font-weight-bolder">
                        {{ Commas(totals) }} {{ $t('baht') }}
                      </h2>

                    </div>
                  </div>

                  <div class="p-2 ml-2">
                    <button
                      class="btn btn-gradient-success mr-1 gap"
                      style="width: 100px;"
                      @click="showDeposit()"
                    >
                      {{ $t('topup') }}
                    </button>

                    <button
                      class="btn btn-outline-danger"
                      style="width: 100px;"
                      @click="showWithdraw()"
                    >
                      {{ $t('withdraw') }}
                    </button>
                  </div>
                </div>
              </b-card>
              <b-card>
                <div class="section-title mb-0">
                  <span class="font-weight-bolder t-size-mobile">{{ $t('ledger') }}</span>

                  <div>
                    <b-form-select
                      v-model="filtertable"
                      :options="option"
                      class="w-50"
                      size="sm"
                    />
                    <b-form-select
                      v-model="selected"
                      :options="options"
                      class="w-50"
                      size="sm"
                    />
                    <div class="d-flex">
                      <div class="income-order-search order-search w-100">
                        <div class="anan-input search-input">
                          <div class="anan-input__inner anan-input__inner--normal">
                            <input
                              v-model="search"
                              type="text"
                              :placeholder="$t('search')"
                              class="anan-input__input"
                              @keyup.enter="getData()"
                            >
                            <div
                              class="anan-input__suffix"
                              @click="getData()"
                            >
                              <i class="anan-input__clear-btn anan-icon" />
                              <i class="anan-input__suffix-icon anan-icon">
                                <i class="fal fa-search" />
                              </i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-card>

              <b-card>
                <b-table
                  striped
                  hover
                  responsive
                  :per-page="perPage"
                  :items="items"
                  :fields="fields"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                >
                  <template #cell(number)="data">
                    <b-link :to="{name: 'admin-user-type-edit', params: {id: data.item.id}}">
                      GUSR-{{ items.length - (perPage * (currentPage - 1) + (data.index + 1)) + 1 }}
                    </b-link>
                  </template>
                  <!-- <template #cell(index)="data">
            {{ perPage * (currentPage - 1) + (data.index + 1) }}
            moment(value).format('DD/MM/YYYY')
          </template> -->

                  <template #cell(createAt)="data">
                    {{ time(data.item.created_at) }}
                  </template>

                  <template #cell(amount)="data">
                    <!-- {{ Commas(data.item.amount) }} -->
                    <span
                      v-if="data.item.amount < 0"
                      class="text-danger"
                    >
                      {{ Commas(data.item.amount) }} ฿
                    </span>
                    <span
                      v-else
                      :style="getAmountStyle(data.item.type, data.item.type_amount)"
                    >
                      {{ getAmountPrefix(data.item.type, data.item.type_amount) }} {{ Commas(data.item.amount) }} ฿
                    </span>
                  </template>

                  <template #cell(balance)="data">
                    {{ Commas(data.item.balance) }}
                  </template>
                  <template #cell(detail)="data">
                    {{ data.item.detail }}
                    <span v-if="data.item.type === 2 && data.item.file_slip">
                      <img
                        :src="GetImg('Filewithdraw',data.item.file_slip)"
                        alt="Preview"
                        class="text-center"
                        width="35"
                        @click="showPicture(data.item.file_slip)"
                      >

                      <vue-image-lightbox-carousel
                        ref="lightbox"
                        :show="showLightbox"
                        :images="images"
                        :show-caption="false"
                        @close="closeBox()"
                      />
                    </span>
                  </template>

                  <template #cell(actions)="data">
                    <feather-icon
                      icon="Edit3Icon"
                      class="mr-1 text-primary cursor-pointer"
                      @click="EditType(data.item)"
                    />
                  </template>
                </b-table>

                <div class="demo-spacing-0 d-flex justify-content-end">
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    first-number
                    last-number
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mb-0"
                    @input="getData()"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </b-card>
            </b-overlay>
          </div>

          <b-modal
            ref="my-deposit"
            :ok-title="$t('key-13')"
            :cancel-title="$t('cancel')"
            :title="$t('topupSystem')"
            @ok="Deposit()"
          >
            <h4>{{ $t('details') }}</h4>

            <div>
              <b-form-group>
                <label for="balance">{{ $t('key-173') }} :</label>
                <b-form-input
                  id="balance"
                  v-model="balance"
                  type="number"
                  placeholder="balance"
                />
              </b-form-group>

              <b-form-group>
                <label for="date">{{ $t('transactionDate') }} :</label>
                <b-form-datepicker
                  id="date"
                  v-model="date"
                />
              </b-form-group>

              <b-form-group>
                <label for="slip">{{ $t('transferEvidence') }} :</label>
                <b-form-file
                  id="slip"
                  v-model="slip"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                />
              </b-form-group>

              <b-form-group>
                <label for="remark">{{ $t('remarks') }} :</label>
                <b-form-textarea
                  id="remark"
                  v-model="remark"
                  :placeholder="$t('remarks')"
                />
              </b-form-group>
            </div>

            <hr>
            <h4>{{ $t('accountToTransfer') }}</h4>
            <div class="d-flex mt-1">
              <img
                src="https://api.plm-cargo.com/images/bank/kbank.png"
                alt=""
                height="70"
                class="mr-1 rounded-lg"
              >

              <div>
                <p class="mb-0">
                  {{ $t('kasikornBank') }}
                </p>
                <p class="mb-0">
                  {{ $t('PLMExpress') }}
                </p>
                <p class="mb-0">
                  127-1-08510-5
                </p>
              </div>
            </div>
          </b-modal>

          <b-modal
            ref="my-withdraw"
            :ok-title="$t('key-13')"
            :cancel-title="$t('cancel')"
            :title="$t('withdrawFromSystem')"
            @ok="withdraw()"
          >
            <div class="text-center">
              <p class="mb-0">
                {{ $t('systemBalance') }}
              </p>
              <h3 class="font-weight-bolder">
                {{ Commas(totals) }} {{ $t('baht') }}
              </h3>
            </div>

            <b-form-group>
              <label for="balance">{{ $t('withdrawalAmount') }} :</label>
              <b-form-input
                id="balance"
                v-model="balance"
                type="number"
                placeholder="balance"
              />
            </b-form-group>
          </b-modal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import {
// BFormRadioGroup,
  BCard,
  BFormDatepicker,
  BFormFile,
  BFormGroup,
  // BIconController,
  BFormInput,
  BFormSelect,
  BFormTextarea,
  BLink,
  // BBadge,
  BModal,
  BOverlay,
  BPagination,
  BTable,
} from 'bootstrap-vue'
import moment from 'moment-timezone'
// import ThisHeader from '@/views/customer/component/ThisHeader.vue'
import VueImageLightboxCarousel from 'vue-image-lightbox-carousel'
import SideBar from './profile/component/SideBar.vue'
// import { join } from 'postcss-rtl/lib/affected-props'

export default {
  components: {
    BPagination,
    // BFormRadioGroup,
    BModal,
    BCard,
    BTable,
    BFormSelect,
    BLink,
    BOverlay,
    // BIconController,
    // ThisHeader,
    SideBar,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BFormDatepicker,
    BFormFile,
    VueImageLightboxCarousel,
  },
  data() {
    return {
      images: [],
      showLightbox: false,
      show: false,
      showOverlay: false,
      imgList: '',
      search: '',
      selected: 'all',
      options: [
        { value: 'all', text: 'ทั้งหมด' },
        { value: 1, text: 'เติมเงิน' },
        { value: 2, text: 'ถอนเงิน' },
        { value: 3, text: 'ชำระค่านำเข้า' },
        { value: 4, text: 'ค่าสินค้า' },
        { value: 5, text: 'ส่วนต่างค่าขนส่งในไทย' },
        { value: 6, text: 'ค่าสินค้าใน MALL' },
        { value: 7, text: 'ฝากจ่ายเถาเปา' },
        { value: 8, text: 'ค่า Commission' },
        { value: 9, text: 'เติมเงินอาลีเพย์' },
        { value: 10, text: 'โอนเงินไปจีน' },
        { value: 11, text: 'แจ้งไม่ได้รับสินค้า' },
        { value: 12, text: 'แจ้งร้านค้าคืนเงิน' },
        { value: 13, text: 'ค่าจัดส่งในไทย' },
        { value: 14, text: 'แลกเงินหยวนเป็นเงินบาท' },
        { value: 15, text: 'ส่งของไปจีน' },
        { value: 16, text: 'แลกแต้ม' },
        { value: 17, text: 'คืนเงิน' },
        { value: 18, text: 'เก็บเงินเพิ่ม' },
        { value: 20, text: 'ยกเลิก Commission' },
        { value: 21, text: this.$t('other') },
      ],
      option: [
        { value: 'all', text: 'ทั้งหมด' },
        { value: 1, text: 'จำนวน' },
        { value: 2, text: 'หมายเลข' },
      ],
      pageOptions: [5, 10, 15, 20],
      currentPage: 1,
      perPage: 10,
      totalRows: 1,
      itemwithdraw: [],
      items: [],
      pic: null,
      totals: 0,
      waitingforcheck: 0,
      waitingforpayment: 0,
      paid: 0,
      OrderSuccess: 0,
      completetrack: 0,
      completewarehouse: 0,
      cancel: 0,
      filtertable: 'all',

      username: null,
      itemsuser: null,
      itemuid: null,
      deposit: [],
      withdraw: [],
      datare: [],
      dataarr: [],
    }
  },
  computed: {
    fields() {
      return [
        {
          key: 'itemnumber_text', label: this.$t('number'), thStyle: { width: '10%' }, thClass: 'text-center',
        },
        {
          key: 'createAt', label: this.$t('transactionDate'), sortable: false, thStyle: { width: '15%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'type_text', label: this.$t('type'), sortable: false, thStyle: { width: '15%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'detail', label: this.$t('details'), sortable: false, thStyle: { width: '30%' }, thClass: 'text-center',
        },
        {
          key: 'amount', label: this.$t('quantity'), sortable: false, thStyle: { width: '15%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'balance', label: this.$t('remainingBalance'), sortable: false, thStyle: { width: '15%' }, thClass: 'text-center', tdClass: 'text-center',
        },
      ]
    },
  },
  async mounted() {
    // this.getUsername()
    await this.getData()
    await this.getBalance()
    this.setDeposit()
    this.GetOrderAll()
  },
  methods: {
    showDeposit() {
      this.$router.push({ name: 'customer-refill-add' })
      // this.$refs['my-deposit'].show()
    },
    showWithdraw() {
      this.$router.push({ name: 'withdraw' })
      // this.$refs['my-withdraw'].show()
    },
    showModal(data) {
      this.pic = data
      this.$refs['my-modal'].show()
    },
    showPicture(picture) {
      this.showLightbox = true
      const img = { path: this.GetImg('Filewithdraw', picture) }
      this.images.push(img)
      // this.$swal({
      //   imageUrl: picture,
      //   imageWidth: 400,
      //   imageHeight: 600,
      //   imageAlt: 'Custom image',
      //   confirmButtonText: 'ปิด',
      // })
    },
    GetImg(pathfile, filename) {
      const matches = filename.match(/^data:image\/([A-Za-z-+\/]+);base64,(.+)$/)
      if (!matches || matches.length !== 3) {
        return `${this.$http.defaults.baseURL}/viewimg?pathfile=${pathfile}/${filename}`
      }
      return filename
    },
    closeBox() {
      this.showLightbox = false
      this.images = []
    },
    time(data) {
      return moment(data).format('DD/MM/YYYY HH:mm:ss')
    },
    getData() {
      this.show = true
      this.items = []
      const params = {
        currentPage: this.currentPage,
        perPage: this.perPage,
        search: this.search,
        filtertable: this.filtertable,
        filtertype: this.selected,
      }
      this.$http.get('/Balance/getAll', { params })
        .then(response => {
          const { Data, total } = response.data
          this.items = Data
          this.totalRows = total
          this.show = false
          // this.items.sort((a, b) => new Date(b.createAt) - new Date(a.createAt))
        })
        .catch(err => {
          console.log(err)
        })
    },

    async getBalance() {
      try {
        const { data: res } = await this.$http.get('/Balance/getbalanceOne')
        this.totals = res.balance
      } catch (e) {
        console.log(e)
      }
    },
    getAmountStyle(amount, typeAmount) {
      if (amount === 21) {
        if (typeAmount) {
          return { color: 'green' }
        }
        return { color: 'red' }
      }
      if ([1, 8, 11, 12, 17, 16].includes(amount)) {
        return { color: 'green' }
      }
      if ([2, 3, 4, 5, 6, 7, 13, 15, 18, 10, 9, 20].includes(amount)) {
        return { color: 'red' }
      }
    },
    getAmountPrefix(value, typeAmount) {
      if (value === 21) {
        if (typeAmount) {
          return '+'
        }
        return '-'
      }
      if ([1, 8, 11, 12, 17, 16].includes(value)) {
        return '+'
      }
      if ([2, 3, 4, 5, 6, 7, 13, 15, 18, 10, 9, 20].includes(value)) {
        return '-'
      }
    },
    downloadImg() {
      const imageURL = '@/assets/images/anan-img/example/1.jpg'
      const link = document.createElement('a')
      link.href = imageURL
      link.download = 'image.jpg'
      link.click()
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-298')}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 600px) {
.gap{
  margin-bottom: 5px !important;
}
.t-size-mobile{
  font-size: 20px !important;
}
}
</style>
